<template>
	<div class="articles">
		<br />
		<h2 style="text-align:center;">Articles, Papers and Other Publications on Privacy and Artificial Intelligence.</h2>
		<b-container>
		  <div class="mt-4">
			<b-card :img-src="require('../assets/ai_brain.jpg')" img-height="250px" img-alt="" img-right class="mb-3" header-class="article-title"
				title="AI and Privacy Cheat Sheet">
				<b-card-text class="text-left">
					<a href="https://s3.amazonaws.com/privacy-and-ai.com/docs/AI and Privacy Cheat Sheet.pdf">
						Companies that are looking to implement AI technologies need to take important and bold steps to avoid mishaps
						especially when handling personal data. Some of these steps are described in this cheat sheet.
					</a>
				</b-card-text>
			</b-card>

			<b-card :img-src="require('../assets/datacube.jpg')" img-height="250px" img-alt="" img-left class="mb-3" header-class="article-title"
				title="Toward Trustworthy AI Development: Mechanisms for Supporting Verifiable Claims">
				<b-card-text class="text-left">
					<a href="https://s3.amazonaws.com/privacy-and-ai.com/docs/2004.07213.pdf">
						Recent progress in artificial intelligence (AI) has enabled a diverse array of applications across commercial,
						scientific, and creative domains. With this wave of applications has come a growing awareness of
						the large-scale impacts of AI systems, and recognition that existing regulations and norms in industry
						and academia are insufficient to ensure responsible AI development.
					</a>
				</b-card-text>
			</b-card>

			<b-card :img-src="require('../assets/analytics.jpg')" img-height="250px" img-alt="" img-right class="mb-3" 
				title="A DEFINITION OF AI: MAIN CAPABILITIES AND DISCIPLINES">

				<b-card-text class="text-left">
					<a href="https://s3.amazonaws.com/privacy-and-ai.com/docs/ADEFINITIONOFAI-ENpdf.pdf">
						The term AI contains an explicit reference to the notion of intelligence. 
						However, since intelligence (both in machines and in humans) is a vague concept, 
						although it has been studied at length by psychologists, biologists, and neuroscientists, 
						AI researchers use mostly the notion of rationality. This refers to the ability to choose 
						the best action to take in order to achieve a certain goal, given certain criteria to be 
						optimized and the available resources. Of course, rationality is not the only ingredient in 
						the concept of intelligence, but it is a significant part of it.
					</a>
				</b-card-text>
			</b-card>

			<b-card :img-src="require('../assets/cybersecurity.jpg')" img-height="250px" img-alt="" img-left class="mb-3" 
				title="ETHICS GUIDELINES FOR TRUSTWORTHY AI">
				<b-card-text class="text-left">
					<a href="https://s3.amazonaws.com/privacy-and-ai.com/docs/AIHLEG_EthicsGuidelinesforTrustworthyAI-ENpdf_EK.pdf">
						The aim of the Guidelines is to promote Trustworthy AI. Trustworthy AI has three components, 
						which should be met throughout the system's entire life cycle: (1) it should be lawful, 
						complying with all applicable laws and regulations (2) it should be ethical, ensuring adherence
						to ethical principles and values and (3) it should be robust, both from a technical and social
						perspective since, even with good intentions, AI systems can cause unintentional harm.
					</a>
				</b-card-text>
			</b-card>

			<b-card :img-src="require('../assets/geometric.jpg')" img-height="250px" img-alt="" img-right class="mb-3" 
				title="Directive on Automated Decision-Making">

				<b-card-text class="text-left">
					<a href="https://s3.amazonaws.com/privacy-and-ai.com/docs/Directive+on+Automated+Decision-Making.pdf">
						The objective of this Directive is to ensure that Automated Decision Systems are deployed
						in a manner that reduces risks to Canadians and federal institutions, and leads to more
						efficient,accurate, consistent, and interpretable decisions made pursuant to Canadian law.
					</a>
				</b-card-text>
			</b-card>
		</div>

		</b-container>

	</div>

				<!-- <article id="first" class="container box style1 right">
				<a href="/docs/2004.07213.pdf" class="image fit"><img src="../assests/datacube.jpg" alt="" /></a>
				<div class="inner">
					<header>
						<a href="/docs/2004.07213.pdf"><h2>Toward Trustworthy AI Development:
							<br />
							Mechanisms for Supporting Verifiable Claims</h2></a>
					</header>
					<p>Recent progress in artificial intelligence (AI) has enabled a diverse array of applications across commercial,
						scientific, and creative domains. With this wave of applications has come a growing awareness of
						the large-scale impacts of AI systems, and recognition that existing regulations and norms in industry
						and academia are insufficient to ensure responsible AI development.</p>
				</div>
			</article>

			<article class="container box style1 left">
				<a href="/docs/ADEFINITIONOFAI-ENpdf.pdf" class="image fit"><img src="images/tech.jpg" alt="" /></a>
				<div class="inner">
					<header>
						<a href="/docs/ADEFINITIONOFAI-ENpdf.pdf"><h2>A DEFINITION OF AI:
							<br />
							MAIN CAPABILITIES AND DISCIPLINES</h2></a>
					</header>
					<p>The term AI contains an explicit reference to the notion of intelligence. 
						However, since intelligence (both in machines and in humans) is a vague concept, 
						although it has been studied at length by psychologists, biologists, and neuroscientists, 
						AI researchers use mostly the notion of rationality. This refers to the ability to choose 
						the best action to take in order to achieve a certain goal, given certain criteria to be 
						optimized and the available resources. Of course, rationality is not the only ingredient in 
						the concept of intelligence, but it is a significant part of it.</p>
				</div>
			</article>

			<article class="container box style1 right">
				<a href="/docs/AIHLEG_EthicsGuidelinesforTrustworthyAI-ENpdf.pdf" class="image fit"><img src="images/security.jpg" alt="" /></a>
				<div class="inner">
					<header>
						<a href="/docs/AIHLEG_EthicsGuidelinesforTrustworthyAI-ENpdf.pdf"><h2>ETHICS GUIDELINES
							<br />
							FOR TRUSTWORTHY AI</h2></a>
					</header>
					<p>The aim of the Guidelines is to promote Trustworthy AI. Trustworthy AI has three components, 
						which should be met throughout the system's entire life cycle: (1) it should be lawful, 
						complying with all applicable laws and regulations (2) it should be ethical, ensuring adherence
						 to ethical principles and values and (3) it should be robust, both from a technical and social
							perspective since, even with good intentions, AI systems can cause unintentional harm.</p>
				</div>
			</article>

			<article class="container box style1 left">
				<a href="/docs/Directive on Automated Decision-Making.pdf" class="image fit"><img src="images/cybersecurity.jpg" alt="" /></a>
				<div class="inner">
					<header>
						<a href="/docs/Directive on Automated Decision-Making.pdf"><h2>Directive on Automated Decision-Making</h2></a>
					</header>
					<p>The objective of this Directive is to ensure that Automated Decision Systems are deployed
						 in a manner that reduces risks to Canadians and federal institutions, and leads to more
							efficient,accurate, consistent, and interpretable decisions made pursuant to Canadian law.</p>
				</div>
			</article> -->
</template>

<script>
// @ is an alias to /src

export default {
	name: 'Articles'
}
</script>

<style scoped>
	.card-title {
		color: #2c3e50;
	}

	a {
		text-decoration: none;
		color: black;
	}

	a:hover {
		color: #2c3e50;
	}
</style>